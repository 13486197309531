.hero {
  display: flex;
  justify-content: space-between;
  color: white;
}

.left-h {
  /* border: 1px solid red; */
  padding: 2rem;
  padding-top: 1.5rem;
  flex: 3;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.right-h {
  /* border: 1px solid rgb(123, 151, 45); */
  flex: 1;
  /* position: relative; */
  background-color: orange;
}

.the-best-ad {
  margin-top: 4rem;
  background-color: #363d42;
  border-radius: 4rem;
  width: fit-content;
  padding: 20px 13px;
  text-transform: uppercase;
  color: white;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.the-best-ad > div {
  width: 5.4rem;
  position: absolute;
  height: 80%;
  border-radius: 3rem;
  background-color: orange;
  left: 8px;
  z-index: 1;
}

.the-best-ad span {
  z-index: 2;
  /* position: relative; */
}
.hero-text {
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: bold;
  text-overflow: inherit;
}
.hero-text div:nth-of-type(3) {
  font-size: 1rem;
  font-weight: normal;
  transform: none;
  letter-spacing: 1px;
  width: 80%;
}

.figures {
  /* border: 1px solid white; */
  display: flex;
  gap: 2rem;
}

.figures div {
  display: flex;
  flex-direction: column;
}

.figures div span:nth-of-type(1) {
  font-size: 2rem;
}

.figures div span:nth-of-type(2) {
  text-transform: uppercase;
  color: gray;
}

.btn {
  padding: 0.5rem;
  font-weight: bold;
  transition: 300ms all;
  border: 4px solid transparent;
  border-radius: 10px;
}
.btn:hover {
  cursor: pointer;
}
.hero-button {
  display: flex;
  gap: 1rem;
}

.hero-button :nth-child(1) {
  color: white;
  width: 8rem;
  background-color: orange;
}

.hero-button :nth-child(2) {
  color: white;
  background-color: transparent;
  border: 2px solid orange;
  width: 8rem;
}

.right-h .btn {
  position: absolute;
  right: 3rem;
  top: 2rem;
  color: black;
}

.heart-rate {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: rgb(83, 80, 80);
  width: fit-content;
  padding: 1rem;
  align-items: flex-start;
  border-radius: 5px;
  position: absolute;
  /* right: 4rem; */
  top: 7rem;
}
.heart-rate img {
  width: 2rem;
}
.heart-rate span:nth-of-type(1) {
  color: gray;
}
.heart-rate span:nth-of-type(2) {
  color: white;
  font-size: 1.5rem;
}

.hero-image {
  position: absolute;
  width: 23rem;
  top: 10rem;
  right: 8rem;
  z-index: 2;
}
.hero-image-back {
  /* border: 1px solid white; */
  position: absolute;
  top: 4rem;
  right: 20rem;
  width: 15rem;
  z-index: 1;
}

.calories {
  display: flex;
  gap: 2rem;
  background-color: #3a454d;
  border-radius: 5px;
  padding: 1rem;
  /* border: 1px solid black; */
  width: fit-content;
  position: absolute;
  top: 32rem;
  right: 28rem;
}

.calories img {
  width: 3rem;
}

.calories div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.calories div span:nth-of-type(1) {
  color: gray;
}
.calories div span:nth-of-type(2) {
  font-size: 1.5rem;
}
.hero-blur {
  width: 23rem;
  height: 30rem;
}

@media screen and (max-width: 768px) {
  .hero {
    flex-direction: column;
  }
  .hero-blur {
    width: 14rem;
  }
  .the-best-ad {
    margin-top: 0;
    font-size: small;
    align-items: center;
  }
}
