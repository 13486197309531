.Reasons {
  /* border: 1px solid white; */
  padding: 0 1rem;
  display: flex;
  gap: 2rem;
}

.left-r {
  /* border: 1px solid rgb(180, 26, 26); */
  flex: 1 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  grid-auto-rows: 1fr;
}

.left-r > :nth-of-type(1) {
  width: 12rem;
  grid-row: 1/3;
  height: 28rem;
}
.left-r > :nth-of-type(2) {
  grid-column: 2/4;
  height: 16rem;
  width: auto;
}
.left-r > :nth-of-type(3) {
  width: 14rem;
  grid-column: 2/3;
}
.left-r > :nth-of-type(4) {
  width: 10rem;
  grid-column: 3/4;
}

.right-r {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-transform: uppercase;
}

.right-span1 {
  color: orange;
  font-weight: bold;
}
.right-r > div {
  color: white;
  font-weight: bold;
  font-size: 3rem;
}
.strock-text {
  padding-right: 1rem;
}

.details-r {
  /* border: 1px solid white; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.details-r div {
  display: flex;
  font-size: 1rem;
  gap: 1rem;
}

.details-r div img {
  height: 2rem;
  width: 2rem;
}

.our-partner {
  font-weight: normal;
  color: rgb(170, 161, 161);
}

.partner {
  display: flex;
  gap: 1rem;
}

.partner img {
  width: 2.5rem;
}
