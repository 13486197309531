.programs {
  display: flex;
  gap: 2rem;
  color: white;
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: bold;
  justify-content: space-evenly;
  font-style: italic;
}

.plan-container {
  margin-top: -4rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 4rem;
}
.plans {
  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: center;
}

.plan {
  display: flex;
  background-color: gray;
  color: white;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem;
  width: 15rem;
}
.plan:nth-child(2) {
  transform: scale(1.1);
  background: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
}
.plan:nth-child(2) > svg {
  fill: white;
}
.plan > svg {
  fill: orange;
  width: 2rem;
  height: 2rem;
}
.plan-name {
  font-weight: bold;
}
.price {
  font-size: 3rem;
  font-weight: bold;
}
.benefits {
  font-size: 0.8rem;
  text-transform: capitalize;
}
.features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.feature {
  align-items: center;
  display: flex;
  gap: 1rem;
}
.feature > img {
  width: 1rem;
}
.plan:nth-child(2) .btn {
  color: orange;
}
.plan-blur-1 {
  height: 30rem;
  width: 20rem;
  top: 6rem;
  left: 0px;
}
.plan-blur-2 {
  height: 30rem;
  width: 20rem;
  top: 6rem;
  right: 0px;
}
